import 'babel-polyfill';

import * as Components from './components/index';

$(function() {

    //new Components.SearchDialog()
    new Components.IframeResize()
    new Components.DatePicker()
    new Components.Form()
    new Components.Footer()
    new Components.SwiperSlide()
    new Components.ScrollMagicAnimation()
    new Components.OdometerCounter()
    new Components.Header()
    new Components.MagnificPopup()
    new Components.DragPanorama()
    new Components.Cursor()
    new Components.Navbar()

});
