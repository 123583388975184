class SwiperSlide {

    constructor() {
        this.popup()
        this.billboard()
        this.homeNews()
        this.homeProduct()
        this.homePartner()
        this.galleryNews()
        this.boardSustain()
    }

    popup() {
        const popup = document.querySelector('.swiper--popup');

        if (popup) {
            new Swiper(popup, {
                autoHeight: true, //enable auto height
                slidesPerView: 1,
                spaceBetween: 0,
                draggable: false,
                effect: 'slide',
                loop: true,
                speed: 1500,
                autoplay: {
                    delay: 6000,
                    disableOnInteraction: false,
                },
                pagination: {
                    el: '.swiper--popup .swiper-pagination',
                    clickable: true,
                },
            });
        }
    }

    billboard() {
        const billboardBg = document.querySelector('.swiper.swiper--billboard-bg');
        const billboardContent = document.querySelector('.swiper.swiper--billboard-content');

        if (billboardBg) {           
            var swiper = new Swiper(".swiper.swiper--billboard-bg", {
                slidesPerView: 1,
                spaceBetween: 0,
                draggable: false,
                effect: 'fade',
                loop: true,
                speed: 1500,
                autoplay: {
                    delay: 6000,
                    disableOnInteraction: false,
                },
                on: {
                    init: function(swiper){
                        playSlide(swiper);
                    },
                    slideChange: function(swiper){
                        playSlide(swiper);
                    }
                }
            });

            function playSlide(swiper) {
                let activeIndex = swiper.activeIndex;
                let previousIndex = swiper.previousIndex;
                let activeVideo = swiper.slides[activeIndex].querySelector('.swiper__video');
                let prevVideo = previousIndex ? swiper.slides[previousIndex].querySelector('.swiper__video') : false;

                if(prevVideo) {
                    prevVideo.pause();
                }

                if(activeVideo){
                    swiper.autoplay.stop();
                    swiper.slides.length == 1 ? activeVideo.loop = true : activeVideo.loop = false;  

                    if (activeVideo.querySelector('source').getAttribute('src') == '') {
                        let vdoUrl = activeVideo.dataset.vdoUrl;
                        activeVideo.querySelector('source').setAttribute('src', vdoUrl);
                        activeVideo.load();
                        activeVideo.addEventListener("loadeddata", function(){
                            this.play();
                        });
                    }else { 
                        activeVideo.currentTime = 0;
                        activeVideo.play();
                    }

                    activeVideo.addEventListener('ended', function(){
                        swiper.slideNext();
                        swiper.autoplay.start();
                    });
                }else {
                    swiper.autoplay.start();
                }
            }
        }

        if (billboardContent) {
            var swiper_content = new Swiper('.swiper.swiper--billboard-content', {
                slidesPerView: 1,
                spaceBetween: 0,
                effect: 'fade',
                loop: true,
                speed: 1500,
                pagination: {
                    el: '.swiper-control--billboard-content .swiper-pagination',
                    clickable: true,
                },
            });
        }

        if (billboardBg && billboardContent) {
            swiper.controller.control = swiper_content;
            swiper_content.controller.control = swiper;
        }
    }

    /* Function for Billboard */
    playSlide(swiper) {
        const activeIndex = swiper.activeIndex;
        const previousIndex = swiper.previousIndex;
        const activeVideo = swiper.slides[activeIndex].querySelector('.swiper__video');
        const prevVideo = previousIndex ? swiper.slides[previousIndex].querySelector('.swiper__video') : false;

        if (prevVideo) {
            prevVideo.pause();
        }

        if (activeVideo) {
            swiper.autoplay.stop();
            activeVideo.loop = swiper.slides.length === 1;

            if (activeVideo.querySelector('source').getAttribute('src') === '') {
                const vdoUrl = activeVideo.dataset.vdoUrl;
                activeVideo.querySelector('source').setAttribute('src', vdoUrl);
                activeVideo.load();
                activeVideo.addEventListener('loadeddata', () => {
                    activeVideo.play();
                });
            } else {
                activeVideo.currentTime = 0;
                activeVideo.play();
            }

            activeVideo.addEventListener('ended', () => {
                swiper.slideNext();
                swiper.autoplay.start();
            });
        } else {
            swiper.autoplay.start();
        }
    }

    homeNews() {
        const homeNews = document.querySelector('.swiper--home-news');

        if (homeNews) {
            new Swiper(homeNews, {
                slidesPerView: 3,
                spaceBetween: 24,
                speed: 1500,
                autoplay: {
                    delay: 8000,
                    disableOnInteraction: false,
                },
                pagination: {
                    el: '.swiper--home-news .swiper-pagination',
                    clickable: true,
                },
                breakpoints: {
                    0: {
                        slidesPerView: 1,
                    },
                    767: {
                        slidesPerView: 2,
                    },
                    991: {
                        slidesPerView: 3,
                    },
                },
            });
        }
    }

    homeProduct() {
        const homeProduct = document.querySelector('.swiper--home-product');

        if (homeProduct) {
            if ($(window).width() >= 992 && $(window).height() >= 768) {
                new Swiper(homeProduct, {
                    slidesPerView: 'auto',
                    spaceBetween: 0,
                });
            } else {
                new Swiper(homeProduct, {
                    spaceBetween: 0,
                    speed: 1500,
                    autoplay: {
                        delay: 8000,
                        disableOnInteraction: false,
                    },
                    pagination: {
                        el: '.swiper--home-product .swiper-pagination',
                        clickable: true,
                    },
                    breakpoints: {
                        0: {
                            slidesPerView: 1,
                        },
                        569: {
                            slidesPerView: 'auto',
                        },
                    },
                });
            }
        }
    }

    homePartner() {
        const homePartner = document.querySelector('.swiper--home-partner');

        if (homePartner) {
            if ($(window).width() >= 695 && $(window).height() >= 780) {
                new Swiper(homePartner, {
                    slidesPerView: 'auto',
                    spaceBetween: 40,
                });
            } else {
                new Swiper(homePartner, {
                    slidesPerView: 'auto',
                    spaceBetween: 40,
                    speed: 1500,
                    autoplay: {
                        delay: 8000,
                        disableOnInteraction: false,
                    },
                    pagination: {
                        el: '.swiper--home-partner .swiper-pagination',
                        clickable: true,
                    },
                    breakpoints: {
                        0: {
                            slidesPerView: 1,
                        },
                        991: {
                            slidesPerView: 'auto',
                        },
                    },
                });
            }
        }
    }

    galleryNews(){
        const gallerySwiper = document.querySelector('.swiper--gallery');
        if (gallerySwiper) {

            let navGallery = new Swiper('.swiper--nav-gallery', {
                spaceBetween: 16,
                freeMode: false,
                breakpoints: {
                    0: {
                        slidesPerView: 3,
                    },
                    992: {
                        slidesPerView: 4,
                    },
                }
            });


            let gallery = new Swiper('.swiper--gallery', {
                effect: "fade",
                spaceBetween: 10,
                navigation: {
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev"
                },
                keyboard: {
                  enabled: true,
                },
                thumbs: {
                    swiper: navGallery,
                }
            });

        }

    }

    boardSustain() {
        const boardSustain = document.querySelector('.swiper--board-sustain');

        if (boardSustain) {
            new Swiper(boardSustain, {
                slidesPerView: 1,
                spaceBetween: 0,
                speed: 1200,
                effect: 'fade', 
                loop: true,
                fadeEffect: {
                    crossFade: true 
                },
                autoHeight: true,
                autoplay: {
                    delay: 8000,
                    disableOnInteraction: false,
                },
                pagination: {
                    el: '.swiper--board-sustain .swiper-pagination',
                    clickable: true,
                },
            });
        }
    }
}

export default SwiperSlide