class ScrollMagicAnimation {
    constructor() {
        this.scrollAnimation()
        this.scrollPartner()
        this.scrollProduct()
        this.scrollMilestone()
    }

    scrollAnimation() {
        const scrollContainers = document.querySelectorAll('.scroll-container');
        if (scrollContainers.length) {
          const controller = new ScrollMagic.Controller();
          const dataOffset = -200;
    
          scrollContainers.forEach((scene) => {
            new ScrollMagic.Scene({
              triggerElement: scene,
              offset: dataOffset,
              duration: 0,
              reverse: false,
            })
              .setClassToggle(scene, 'animation')
              .addTo(controller);
          });
        }
    }

    scrollProduct() {
        const $homeProductPin = $("#homeProductPin");

        if ($homeProductPin.length) {
            const swiperProduct = document.querySelector('.swiper.swiper--home-product').swiper;
            const controller = new ScrollMagic.Controller();

            if ($(window).width() >= 992 && $(window).height() >= 768) {
                new ScrollMagic.Scene({
                    triggerElement: $homeProductPin[0],
                    offset: $homeProductPin[0].offsetHeight,
                    triggerHook: 1,
                    duration: $homeProductPin[0].offsetHeight,
                })
                    .setPin($homeProductPin[0])
                    .on("progress", function (event) {
                        swiperProduct.setProgress(event.progress * 1.1, 0)
                    })
                    .addTo(controller);
            }
        }
    }

    scrollPartner() {
        const $homePartnerPin = $("#homePartnerPin");

        if ($homePartnerPin.length) {
            const swiperPartner = document.querySelector('.swiper.swiper--home-partner').swiper;
            const controller = new ScrollMagic.Controller();

            if ($(window).width() >= 695 && $(window).height() >= 768) {
                new ScrollMagic.Scene({
                    triggerElement: $homePartnerPin[0],
                    offset: $homePartnerPin[0].offsetHeight,
                    triggerHook: 1,
                    duration: $homePartnerPin[0].offsetHeight,
                })
                    .setPin($homePartnerPin[0])
                    .on("progress", function (event) {
                        swiperPartner.setProgress(event.progress * 1.1, 0)
                    })
                    .addTo(controller);
            }
        }
    }

    scrollMilestone() {
        const navLinkMilestones = document.querySelectorAll('.navbar.navbar--milestone .nav-link');
        const blockGroupMilestones = document.querySelectorAll('.block.block--milestone .block__group');

        if (navLinkMilestones.length && blockGroupMilestones.length) {
            $('html').css('scroll-padding-top', '60px');
            
            const controller = new ScrollMagic.Controller({globalSceneOptions: {offset: 0}});
            const navLinkMilestoneIds = [];
            const blockGroupMilestoneIds = [];
            const milestoneMap = new Map();
            
            
            blockGroupMilestones.forEach(blockGroupMilestone => {
                const blockGroupMilestoneId = blockGroupMilestone.id;
                blockGroupMilestoneIds.push(blockGroupMilestoneId);
            });

            navLinkMilestones.forEach(navLinkMilestone => {
                const navLinkMilestoneId = navLinkMilestone.id;
                navLinkMilestoneIds.push(navLinkMilestoneId);
            });

            for (let i = 0; i < blockGroupMilestoneIds.length; i++) {
                const blockGroupMilestoneId = blockGroupMilestoneIds[i];
                const navLinkMilestoneId = navLinkMilestoneIds[i];
                milestoneMap.set(blockGroupMilestoneId, navLinkMilestoneId);
            }

            milestoneMap.forEach((navLinkMilestoneId, blockGroupMilestoneId) => {
                const element = document.getElementById(blockGroupMilestoneId);
                let height = element.clientHeight;

                new ScrollMagic.Scene({
                    triggerElement: `#${blockGroupMilestoneId}`,
                    duration: height,
                })
                    .setClassToggle(`#${navLinkMilestoneId}, #${blockGroupMilestoneId}`, "active")
                    // .addIndicators()
                    .addTo(controller);
            });
        }
    }
}
export default ScrollMagicAnimation
