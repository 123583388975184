class Footer {

    constructor() {
        this.footerMenu()
    }
    
    footerMenu() {

        if($('.nav--footer-menu').length) {

            $('.nav.nav--footer-menu .nav-header').on('click', function(e) {
                e.preventDefault();
                
                // Check if the clicked element has an href attribute
                if ($(this).attr('href')) {
                    window.location.href = $(this).attr('href');
                    return; // Stop further execution
                }

                if (!$(this).parent().hasClass('show')) {        
                    $('.nav.nav--footer-menu .nav-subitem').slideUp(400);
                    $('.nav.nav--footer-menu .nav-item').removeClass('show');
                }

                $(this).parent().children('.nav-subitem').slideToggle();
                $(this).parent().toggleClass('show');
                
            });

            $(window).resize(function() {

                if ($(window).width() >= 768) {
                    $('.nav.nav--footer-menu .nav-item').removeClass('show');
                    $('.nav.nav--footer-menu .nav-subitem').show();
                } else {
                    $('.nav-subitem').hide();
                }

            }).resize();

        }

    }

}
export default Footer
