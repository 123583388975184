//fonts
import './fonts/poppins/stylesheet.css'

//icon
import './fonts/icomoon/style.css'

//css
import './scss/index.scss'

//script
import './js/index.js'