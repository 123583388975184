class Form {

    constructor() {
        // this.multipleAttachFile($('.multiple-file'), 'doc|docx|pdf', 2)
        this.file()
        this.privacyStatement()
        this.selectSort()
        
    }

    multipleAttachFile ($el, fileType, maxFile) {

        if($el.length){
            $el.MultiFile(
                {
                    accept: fileType,
                    max: maxFile
                }
            );
        }
        
    }

    file() {
        $(".custom-file-input").on("change", function () {
            var files = Array.from(this.files)
            var fileName = files.map(f => { return f.name }).join(", ")
            $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
        });

    }

    privacyStatement () {

        let $checkbox = $('input[name="privacyStatement"]');
		let $btn = $('button[type="submit"]');

        if($btn.length && $checkbox.length) {
            $checkbox.click(function () {
                if($(this).prop('checked')) {
                    $btn.removeAttr('disabled');
                } else {
                    $btn.attr('disabled', 'disabled');
                }
            })
        }

    }

    selectSort() {

        if(document.querySelectorAll("#sortIngredientFunctions").length) {

            document.getElementById('sort').addEventListener('change', function() {
                const ingredientList = document.getElementById('ingredient-list');
                if (!ingredientList) return; // Ensure ingredient list exists
                const ingredients = Array.from(ingredientList.getElementsByClassName('nav-item'));
                const sortType = this.value;
        
                if (sortType === 'A-Z') {
                    ingredients.sort((a, b) => a.textContent.localeCompare(b.textContent));
                } else if (sortType === 'Z-A') {
                    ingredients.sort((a, b) => b.textContent.localeCompare(a.textContent));
                } else if (sortType === 'Popular') {
                    ingredients.sort((a, b) => a.getAttribute('data-rank') - b.getAttribute('data-rank'));
                }
        
                // Clear existing list
                ingredientList.innerHTML = '';
        
                // Append sorted ingredients
                ingredients.forEach(item => {
                    ingredientList.appendChild(item);
                });
            });


        }
               
    }

}

export default Form