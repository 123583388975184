let $header = $('.header'),
    $body = $('body');
    
class Header {

    constructor() {
        this.headerHome()
        this.HeaderExpand()  
        this.OnScrolling()
        this.menuActive()
        this.closeDropdown()
    }

    headerHome() {
        if ($('.home').length) {
            $('.header').addClass('header--home');
            $('.main').addClass('main--home');
        }

        if ($('.section--message-director').length) {
            $('.header').addClass('header--white');
        }
    }

    HeaderExpand() {
        let $dropdown = $('.navbar.navbar--header .nav-link.dropdown-toggle');
        let $hamburger = $('.navbar.navbar--hamburger .navbar-collapse');

        if ($header) {
            $dropdown.on('show.bs.dropdown', function (e) {
                $body.css('overflow', 'hidden');
            });
        
            $dropdown.on('hidden.bs.dropdown', function (e) {          
                $body.css('overflow', 'unset');
            });
            
            $hamburger.on('show.bs.collapse', function (e) {
                $body.css('overflow', 'hidden');
            });
            
            $hamburger.on('hidden.bs.collapse', function (e) {          
                $body.css('overflow', 'unset');
            });
        }    
    }

    OnScrolling() {
        var currentScroll;

        if ($header) {
            $(window).scroll(function (e) {
                if ($('.header').length) {
                    currentScroll = $(this).scrollTop();
                    if (currentScroll > 100) { $header.addClass('header--scroll'); }
                    else { $header.removeClass('header--scroll'); }
                }

                const scrollUp = function(e) {
                    $header.addClass('header--scroll-up');
                    $header.removeClass('header--scroll-down');
    
                    if(window.scrollY == 0) {
                        $header.removeClass('header--scroll-up');
                    }                
                }
    
                const scrollDown = function() {                    
                    if (screen.width >= 992) {                         
                        $header.removeClass('header--scroll-up');
                        $header.addClass('header--scroll-down');
                        $header.addClass('header--active');
                    }
                }
    
                if(this.oldScroll > this.scrollY){
                    scrollUp();
                }
                else{
                    scrollDown();
                }   
    
                this.oldScroll = this.scrollY;

            });

            
        }        
    }

    menuActive() {
        const url = window.location.href;
        const host = window.location.protocol + "//" + window.location.host;
        const path = url.replace(host, "");

        // For Template
        let myHost = $(location).attr('host');
        let myURL = $(location).attr('href').split(myHost)[1];
        // For Production
        // let myURL = $(location).attr('href').split('?')[0];

        $('.navbar--header').find('a[href="' + myURL + '"]').addClass('active');
        // $('.dropdown-link.active').parents('.nav-item').addClass('active');

        if ($('.dropdown-link.active').parents('.dropdown-menu--lv2')) {
            $('.dropdown-link.active').parents('.nav-item').addClass('active');
        }
        
        if ($('.dropdown-link.active').parents('.dropdown-menu--lv3')) {
            $('.dropdown-link.active').parents('.dropdown-item').addClass('active');
        }

    }

    closeDropdown() {
        let $closeDropdownNormal = $('.navbar.navbar--header .navbar-nav .dropdown-menu .dropdown-menu__close');
        let $closeDropdownHamburger = $('.navbar.navbar--hamburger .navbar-nav__close');

        if ($closeDropdownNormal) {
            $closeDropdownNormal.on('click', function() {
                $('.navbar.navbar--header .navbar-nav .dropdown-toggle.show').dropdown('hide');
            });
        }

        if ($closeDropdownHamburger) {
            $closeDropdownHamburger.on('click', function() {
                $('.navbar.navbar--hamburger .navbar-collapse.show').collapse('hide');
            });
        }
        
    }

}

export default Header