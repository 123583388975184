class DragPanorama {

    constructor() {
        this.dragImage()
    }
    
    dragImage() {

        $(".draggable__item").draggable({
            axis: "x",
            containment: [ -50000, 0, 50000, 0]
        });

    }

}
export default DragPanorama