class Navbar {

    constructor() {
        this.NavbarPrimary()
        this.NavbarPartner()
    }

    NavbarPrimary() {
        const navbarNaturePrimary = $('.navbar.navbar--primary');
        
        if (navbarNaturePrimary) {
            $('.navbar.navbar--primary .nav-item').on('click', function() {
                var title = $(this).find('.nav-link').text();
                $('.navbar.navbar--primary .nav-toggler__title').html(title);
            });
        }
    }

    NavbarPartner() {
        const navbarPartner = $('.navbar.navbar--primary#navbarPartner');

        if (!navbarPartner.length) return;

        const urlParams = new URLSearchParams(window.location.search);
        const activeTabId = urlParams.get('tab');

        if (navbarPartner) {    
            const activeNavItem = navbarPartner.find(`.nav-item[id="${activeTabId}"]`);

            // Activate Bootstrap tab
            const tab = new bootstrap.Tab(activeNavItem[0]); 
            tab.show();

            // Update active class and scroll
            navbarPartner.find('.nav-item').removeClass('active');
            activeNavItem.addClass('active');
            navbarPartner[0].scrollIntoView({ behavior: 'smooth' }); 
        }
    }
}

export default Navbar