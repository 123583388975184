class Cursor {
    constructor() {
        this.dragCursor();
    }

    dragCursor() {
        const cursorContainers = document.querySelectorAll('.cursor-container');

        if (cursorContainers.length) {
            cursorContainers.forEach(cursorContainer => {
                const myCursor = document.querySelector('.cursor');

                cursorContainer.addEventListener('mousemove', e => {
                    myCursor.classList.remove('hide');
                    myCursor.setAttribute("style", "top: " + (e.pageY - 30) + "px; left: " + (e.pageX - 100) + "px;")
                })

                cursorContainer.addEventListener('mouseleave', e => {
                    myCursor.classList.add('hide');
                })
            })
        }
    }
}

export default Cursor
