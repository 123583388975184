class MagnificPopup {

    constructor() {
        this.vdo();

    }

    vdo() {
        if ($('.popup-vdo').length) {
            $('.popup-vdo').magnificPopup({
                disableOn: 700,
                type: 'iframe',
                mainClass: 'mfp-fade',
                removalDelay: 160,
                preloader: false,
                // fixedContentPos: false,
                // callbacks: {
                //     open() {

                //         if($('.video-popup-content video').length){
                //             var magnificVideo = $('.video-popup-content').find('video')[0];
                //             magnificVideo.play();
                //         }
                //     },
                //     close() {
                //         var magnificVideo = $('.video-popup-content').find('video')[0];
                //         magnificVideo.pause();
                //         magnificVideo.currentTime = 0;
                //     }
                // }
                iframe: {
                    patterns: {
                        youtube: {
                            index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

                            id: 'v=', // String that splits URL in a two parts, second part should be %id%
                            // Or null - full URL will be returned
                            // Or a function that should return %id%, for example:
                            // id: function(url) { return 'parsed id'; }

                            src: '//www.youtube.com/embed/%id%?autoplay=1' // URL that will be set as a source for iframe.
                          },
                    }
                },

            });
        }
    }



}


export default MagnificPopup
