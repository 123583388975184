class OdometerCounter {

    constructor() {
        this.updateValue();
        this.clearValue();
    }

    updateValue() {
        if ($('.odometer').length) {
            var el = document.querySelectorAll('.odometer');
            $(window).scroll(function () {
                var scroll = $(window).scrollTop(),
                    scrollTop = $('.odometer-container').offset().top,
                    scrollDown = scrollTop + $('.odometer-container').outerHeight(),
                    scrollSet = scrollTop - 800,
                    odometerValue = 0;
                if (scroll <= scrollSet || scroll >= scrollDown) {
                    odometerValue = 1
                    if (odometerValue === 1) {
                        $(el).each(function (index, obj) {
                            var setValue = obj.dataset.value;
                            var od = new Odometer({
                                el: obj,
                                value: 0,
                                duration: 1000
                            });
                            setTimeout(() => {
                                od.update(setValue);
                            }, 500);
                        });
                    }
                } else {
                    odometerValue = 0
                }
            });
        }
    }

    clearValue() {
        if ($('.odometer').length) {
            var el = document.querySelectorAll('.odometer');
            $(window).scroll(function () {
                var scroll = $(window).scrollTop(),
                    scrollTop = $('.odometer-container').offset().top,
                    scrollDown = scrollTop + $('.odometer-container').outerHeight(),
                    scrollSet = scrollTop - 800,
                    odometerValue = 0;
                if (scroll <= scrollSet || scroll >= scrollDown) {
                    odometerValue = 1
                    if (odometerValue === 1) {
                        $(el).each(function (index, obj) {
                            var od = new Odometer({
                                el: obj,
                                value: 0,
                                duration: 0
                            });
                            od.update(0);
                        });
                    }
                } else {
                    odometerValue = 0
                }
            });

        }
    }
}
export default OdometerCounter